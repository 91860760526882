import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Badge, Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { fetchAllDataAPI } from '../utils/api'
import { toast } from 'react-toastify'
import moment from 'moment'

const AllEmployee = () => {
    const [isLoading, setIsloading] = useState(false);
    const [employee, setEmployee] = useState([]);

    useEffect(() => {
        getAllEmployee();

    }, []);

    const getAllEmployee = () => {
        setIsloading(true);
        fetchAllDataAPI('account-get-all-employees-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.warning("Something went wrong!!");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ACCOUNT::ALL EMPLOYEES</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Employees</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="table-responsive">
                                <Table size="sm" responsive="sm" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Cmp Email</th>
                                            <th>Emp Code</th>
                                            <th>DOB</th>
                                            <th>Gender</th>
                                            <th>Department</th>
                                            <th>Designation</th>
                                            <th>DOJ</th>
                                            <th>Status</th>
                                            <th>Job Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                            </>
                                            :
                                            employee ?
                                                employee?.map((employee, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td className="d-flex" style={{ gap: "5px" }}>
                                                            {employee?.emp_name}
                                                            {(() => {
                                                                if (employee?.emp_leave_status === "Active") {
                                                                    return (
                                                                        <div className="sticky_header__availabl">
                                                                            <span className="sticky_header__available_circle"></span>
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <div className="sticky_header_absence">
                                                                            <span className="sticky_header_absence_circle"></span>
                                                                        </div>
                                                                    )
                                                                }
                                                            })()}
                                                        </td>
                                                        <td>{employee?.emp_mobile}</td>
                                                        <td>{employee?.emp_personal_email}</td>
                                                        <td>{employee?.emp_company_email}</td>
                                                        <td><Badge bg="warning">{employee?.emp_code}</Badge></td>
                                                        <td><Badge bg="success">{moment(employee?.emp_dob).format('Do MMMM YYYY')}</Badge ></td>
                                                        <td>{employee?.emp_gender}</td>
                                                        <td>{employee?.emp_department}</td>
                                                        <td>{employee?.designation_name}</td>
                                                        <td>{moment(employee?.emp_joining_date).format('Do MMMM YYYY')}</td>
                                                        <td>
                                                            {(() => {
                                                                if (employee?.emp_status === "Active") {
                                                                    return (
                                                                        <span className="badge bg-success statustype">{employee?.emp_status}</span>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <span className="badge bg-danger statustype">{employee?.emp_status}</span>
                                                                    )
                                                                }
                                                            })()}
                                                        </td>
                                                        <td><Badge bg="secondary">{employee?.emp_job_type}</Badge></td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={10} className="text-center">Data not founds...</td>
                                                </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Cmp Email</th>
                                            <th>Emp Code</th>
                                            <th>DOB</th>
                                            <th>Gender</th>
                                            <th>Department</th>
                                            <th>Designation</th>
                                            <th>DOJ</th>
                                            <th>Status</th>
                                            <th>Job Type</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AllEmployee