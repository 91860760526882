import React, { useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSingleDataFromDatabase } from '../utils/api'
import DashboardSection from '../components/dashboard/DashboardSection'
import DashboardOverview from '../components/dashboard/DashboardOverview'

const Dashboard = () => {
    const navigate = useNavigate();
    const accountid = localStorage.getItem("accountdata");
    const [account, setAccount] = useState([]);

    useEffect(() => {
        if (accountid) {
            navigate('/dashboard');
            getDashboardSingleEmpData(accountid);
        }
        else {
            navigate("/");
        }
    }, [accountid]);

    const getDashboardSingleEmpData = (accountid) => {
        getSingleDataFromDatabase(`account-get-single-member-data/${accountid}`).then((res) => {
            if (res.data.status === 1) {
                setAccount(...[res.data.accountdata]);
            }
            else {
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ACCOUNT::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                {(() => {
                    let today = new Date();
                    let curHr = today.getHours();
                    if (curHr < 12) {
                        return (
                            <h4 className="dashboardname">Good Morning, <span>{account?.account_name}</span></h4>
                        )
                    }
                    else if (curHr < 17) {
                        return (
                            <h4 className="dashboardname">Good Afternoon, <span>{account?.account_name}</span></h4>
                        )
                    }
                    else {
                        return (
                            <h4 className="dashboardname">Good Evening, <span>{account?.account_name}</span></h4>
                        )
                    }
                })()}

                <div className="">
                    <DashboardSection />
                </div>
                <div className="">
                    <DashboardOverview />
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
