import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { activeStatus, addFormData, fetchAllDataAPI, getSingleDataFromDatabase, removeDataFromList } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaPlus, FaRegEdit, FaRegEye, FaRegTrashAlt } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { GrDocumentUpdate } from "react-icons/gr";

const AllProduct = () => {
    const [product, setProduct] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const accountid = localStorage.getItem("accountdata");
    const [qtyvalues, setQuantityValue] = useState();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = product.slice(firstIndex, lastIndex);
    const npage = Math.ceil(product.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const [show, setShow] = useState(false);
    const [productid, setProductId] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (pid) => {
        setShow(true);
        setProductId((pid));
    }
    useEffect(() => {
        if (accountid) {
            navigate('/all-products');
            getAllProducts(accountid)
        }
        else {
            navigate("/");
        }
    }, [accountid]);

    const getAllProducts = () => {
        setIsloading(true);
        fetchAllDataAPI('account-get-all-product-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setProduct(...[res.data.product]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }


    const [detailshow, setDetailShow] = useState(false);
    const handleDetailClose = () => setDetailShow(false);
    const [pdetails, setPdetails] = useState([]);
    const [phistory, setPHistory] = useState([]);
    const handleDetailShow = (pid) => {
        setDetailShow(true);
        getSingleDataFromDatabase(`warehouse-get-product-details-history/${pid}`).then((res) => {
            if (res.data.status === 1) {
                setPdetails(...[res.data.product]);
                setPHistory(...[res.data.phistory]);
            }
            else {
                toast.warning("Check your connection")
            }
        })
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ACCOUNT::ALL PRODUCTS</title>
            </Helmet>
            <Modal
                size="lg"
                show={detailshow}
                onHide={() => handleDetailClose(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Product Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="productdetails">
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Company</span>
                                    <span className="pvalue">{pdetails.company_name}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Category</span>
                                    <span className="pvalue">{pdetails.cat_name}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Name</span>
                                    <span className="pvalue">{pdetails.product_name}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Price</span>
                                    <span className="pvalue">₹ {pdetails.product_price}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Sell Price</span>
                                    <span className="pvalue">₹ {pdetails.product_sell_price}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product SKU Number</span>
                                    <span className="pvalue">{pdetails.product_sku_number}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Tax Rate</span>
                                    <span className="pvalue">₹ {pdetails.product_tax_rate}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Discount Rate</span>
                                    <span className="pvalue">₹ {pdetails.product_disc_rate}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Height(CM)</span>
                                    <span className="pvalue">{pdetails.product_height}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Width(CM)</span>
                                    <span className="pvalue">{pdetails.product_width}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Bredth(CM)</span>
                                    <span className="pvalue">{pdetails.product_bredth}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Length(CM)</span>
                                    <span className="pvalue">{pdetails.product_length}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Weight(gm)</span>
                                    <span className="pvalue">{pdetails.product_weight}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Total QTY</span>
                                    <span className="pvalue">{pdetails.product_total_qty}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Manufacturing Date</span>
                                    <span className="pvalue badge badge-warning">{moment(pdetails.product_mnf_date).format('Do MMMM YYYY')}{ }</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Expiry Date</span>
                                    <span className="pvalue badge badge-danger">{moment(pdetails.product_exp_date).format('Do MMMM YYYY')}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product BAR CODE</span>
                                    <span className="pvalue">{pdetails.product_bar_code}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Select Product Form</span>
                                    <span className="pvalue">{pdetails.product_form}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Supplier Name</span>
                                    <span className="pvalue">{pdetails.product_supplier}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product Shipping Charge</span>
                                    <span className="pvalue">₹ {pdetails.product_shipping_charge}</span>
                                </div>
                            </Col>
                            <Col sm md="4">
                                <div className="detailsdiv">
                                    <span className="pname">Product HSN Code</span>
                                    <span className="pvalue">{pdetails.product_hsn_code}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm md="12">
                                <div className="detailsdiv">
                                    <span className="pname">Product Description</span>
                                    <span className="pvalue">{pdetails.product_desc}</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="detailsheading">
                            <h5>Product History</h5>
                        </div>
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Add Quantity</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {phistory?.map((phistory, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{phistory?.product_history_qty}</td>
                                        <td><span className="badge badge-success">{moment(phistory?.history_date).format('Do MMMM YYYY')}</span></td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Products</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="table-responsive">
                                <Table size="sm" responsive="sm" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Comp Name</th>
                                            <th>Cat Name</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Sell Price</th>
                                            <th>SKU</th>
                                            <th>Current QTY</th>
                                            <th>Total QTY</th>
                                            <th>HSN Number</th>
                                            <th>Supplier</th>
                                            <th>Expiry Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {Array.from({ length: 14 }).map((_, index) => (
                                                        <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                    ))}
                                                </tr>
                                            </>
                                            :
                                            records?.map((records, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><span className="badge badge-info">{records?.company_name}</span></td>
                                                    <td><span className="badge badge-success">{records?.cat_name}</span></td>
                                                    <td>{records?.product_name}</td>
                                                    <td>₹ {records?.product_price}</td>
                                                    <td>₹ {records?.product_sell_price}</td>
                                                    <td>{records?.product_sku_number}</td>
                                                    <td>{records?.product_current_qty}</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <span className="qtybadge badge badge-secondary">{records?.product_total_qty}</span>
                                                        </div>
                                                    </td>
                                                    <td><span className="badge badge-primary">{records?.product_hsn_code}</span></td>
                                                    <td>{records?.product_supplier}</td>
                                                    <td>
                                                        <span className="badge badge-warning">{moment(records?.product_exp_date).format('Do MMMM YYYY')}</span>
                                                    </td>
                                                    {(() => {
                                                        if (records?.product_status === "Active") {
                                                            return (
                                                                <td><span className="badge bg-success btnstatus">{records?.product_status}</span></td>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <td><span className="badge bg-danger btnstatus">{records?.product_status}</span></td>
                                                            )
                                                        }
                                                    })()}
                                                    <td>
                                                        <button className="actionbtn badge badge-success" onClick={() => handleDetailShow(records.product_id)}><FaRegEye style={{ fontSize: "15px" }} /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Comp Name</th>
                                            <th>Cat Name</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Sell Price</th>
                                            <th>SKU</th>
                                            <th>Current QTY</th>
                                            <th>Total QTY</th>
                                            <th>HSN Number</th>
                                            <th>Supplier</th>
                                            <th>Expiry Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                                <nav>
                                    <ul className="pagination justify-content-end round-pagination">
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                        </li>
                                        {
                                            numbers.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                    <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                                </li>
                                            ))
                                        }
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllProduct